import { createSlice } from "@reduxjs/toolkit";
const modalSlice = createSlice({
  name: "modals",
  initialState: { isModalOpen: false ,firstOpen:false},
  //   initialState: productData,
  reducers: {
    ModalOpen: (state, action) => {
      return { ...state, isModalOpen: action.payload ,firstOpen:true};
    },
  },
});

export const { ModalOpen } = modalSlice.actions;

export default modalSlice.reducer;
