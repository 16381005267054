import { CircularProgress } from "@mui/material";
import React, { useEffect, useRef, useState } from "react";
import { useSelector } from "react-redux";
import { useLocation, useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import Card from "../../../assets/Card.png";
import { CliftonUrl, LocalImageUrl, LocalUrl } from "../../../config/env";
import { Arrow, Money, NOImage, ZameerAnsariLogo } from "../../../config/icon";
import { PayemntMethodAPI } from "../apiCall";
import Footer from "../footer";
import Thankyou from "../thankyou";
import "./style.css";
function CheckoutDetail() {
  const location = useLocation();
  let orderLocation = JSON.parse(localStorage.getItem("location"));
  const inputRef = useRef();
  const navigate = useNavigate();
  const options = [
    { value: "Mr", label: "Mr" },
    { value: "Mrs", label: "Mrs" },
    { value: "Miss", label: "Miss" },
  ];
  const [name, setName] = useState("");
  const [mobileNumber, setMobileNumber] = useState("");
  const [alternateNumber, setAlternateNumber] = useState("");
  const [address, setAddress] = useState("");
  const [landmark, setLandmark] = useState("");
  const [email, setEmail] = useState("");
  const [instructions, setInstructions] = useState("");
  const [payment, setPayment] = useState("");
  const [paymentMethod, setPaymentMethod] = useState("cash");
  const details = JSON.parse(localStorage.getItem("details")) || {};
  const [enableMethod, setEnableMethod] = useState([]);
  const [showOptions, setShowOptions] = useState(false);
  const [selectedOption, setSelectedOption] = useState(options[0]);
  const [thankyouModal, setThankyouModal] = useState(false);
  const [loading, setLoading] = useState(false);
  const [isLoadingLogo, setIsLoadingLogo] = useState(true);
  useEffect(() => {
    // Simulate a delay for fetching data or rendering complex components
    setTimeout(() => {
      setIsLoadingLogo(false);
    }, 1000); // Adjust the delay as needed
  }, []);
  const handleOptionSelect = (option) => {
    setSelectedOption(option);
    inputRef.current.value = option.label; // Update the input value
    setShowOptions(false);
  };

  const toggleOptions = () => {
    setShowOptions(!showOptions);
  };

  const products = useSelector((state) => state.products);

  useEffect(() => {
    if (!products.length) {
      navigate(-1);
    }
  }, [products]);

  const handleMobileNumberChange = (e) => {
    if (e && e.target) {
      const inputValue = e.target.value;
      // Remove any non-digit characters
      const sanitizedInput = inputValue.replace(/\D/g, "");
      let formattedInput = "";
      if (sanitizedInput.length > 0) {
        formattedInput = sanitizedInput.slice(0, 4);
        if (sanitizedInput.length > 4) {
          formattedInput += "-" + sanitizedInput.slice(4, 11);
        }
      }

      // Limit the input to 11 characters (including the hyphen)
      if (formattedInput.length <= 12) {
        setMobileNumber(formattedInput);
      }
    }
  };

  const handleAlternateNumberChange = (e) => {
    if (e && e.target) {
      const inputValue = e.target.value;
      // Remove any non-digit characters
      const sanitizedInput = inputValue.replace(/\D/g, "");

      // Format the input as 4 digits followed by a hyphen and 7 digits
      let formattedInput = "";
      if (sanitizedInput.length > 0) {
        formattedInput = sanitizedInput.slice(0, 4);
        if (sanitizedInput.length > 4) {
          formattedInput += "-" + sanitizedInput.slice(4, 11);
        }
      }

      // Limit the input to 11 characters (including the hyphen)
      if (formattedInput.length <= 12) {
        setAlternateNumber(formattedInput);
      }
    }
  };

  const handlePaymentMethod = (e) => {
    if (e == "cash") {
      setPaymentMethod("cash");
      setPayment("");
    } else if (e == "Swipe Debit/Credit Card at your Door Step") {
      setPaymentMethod("Swipe Debit/Credit Card at your Door Step");
      // setPayment("Swipe Debit/Credit Card at your Door Step");
    } else if (e == "Card Payment") {
      setPaymentMethod("Card Payment");
      // setPayment("Card Payment");
    }
  };
  const total = products.reduce((accumulator, product) => {
    const itemPrice = product.variation
      ? product.variation.price * product.quantity
      : product.price * product.quantity;
    return accumulator + itemPrice;
  }, 0);
  // console.log();
  let placeOrder = (e) => {
    e.preventDefault();
    setLoading(true);
    localStorage.setItem("details", JSON.stringify({}));
    const emailRegex = /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i;
    if (!emailRegex.test(email)) {
      toast.error("Please enter a valid email address");
      setLoading(false);
    } else if (
      (!name,
        !mobileNumber,
        // !alternateNumber,
        !address,
        // !landmark,
        !email)
      // !instructions,
      // !payment
    ) {
      toast.error("Please fill the information");
      setLoading(false);
    } else {
      var myHeaders = new Headers();
      myHeaders.append("Content-Type", "application/json");
      var raw = JSON.stringify({
        title: selectedOption.value,
        name: name,
        mobile_number: mobileNumber,
        alternate_number: alternateNumber,
        address: { complete: address, location: orderLocation.value },
        nearest_landmark: landmark,
        email: email,
        delivery_instructions: instructions,
        payment: paymentMethod,
        status: "processing",
        ProductOrder: products,
        delivery_charges: location.state.delivery,
        tax: location.state.Totaltax,
        total_amount: total,
        change_cash: payment,
      });
      var requestOptions = {
        method: "POST",
        headers: myHeaders,
        body: raw,
        redirect: "follow",
      };

      fetch(
        `${LocalUrl
        }/OrderPlace/add-order-place`,
        requestOptions
      )
        .then((response) => response.json())
        .then((result) => {
          if (result.data) {
            setName("");
            setAddress("");
            setAlternateNumber("");
            setMobileNumber("");
            setEmail("");
            setInstructions("");
            setLandmark("");
            setPayment("");
            setLoading(false);
            setThankyouModal(!thankyouModal);
          } else {
            toast.error(result.message);
            setName("");
            setAddress("");
            setAlternateNumber("");
            setMobileNumber("");
            setEmail("");
            setInstructions("");
            setLandmark("");
            setPayment("");
            setLoading(false);
          }
        })
        .catch((error) => {
          setLoading(false);
          setName("");
          setAddress("");
          setAlternateNumber("");
          setMobileNumber("");
          setEmail("");
          setInstructions("");
          setLandmark("");
          setPayment("");
          // toast.error(error.message);
        });
    }
  };

  useEffect(() => {
    if (Object.keys(details).length) {
      setName(details.name);
      setMobileNumber(details.mobileNumber);
      setAlternateNumber(details.alternateNumber);
      setAddress(details.address);
      setLandmark(details.landmark);
      setEmail(details.email);
      setInstructions(details.instructions);
      setPayment(details.payment);
      setPaymentMethod(details.paymentMethod);
    }
  }, []);

  useEffect(() => {
    // Scroll to the top of the page on component mount
    window.scrollTo(0, 0);
    PayemntMethodAPI()
      .then((result) => {
        setEnableMethod(result);
      })
      .catch((error) => console.log("Error in fetching data: ", error));
  }, []);
  return !products.length ? null : (
    <div className="main-checkout-container">
      <div className="checkout-header">
        {/* {isLoadingLogo ? (
          <Skeleton
            variant="rectangular"
            width={80}
            height={60}
            animation="wave"
          />
        ) : ( */}
        <img src={ZameerAnsariLogo} alt="" />
        {/* )} */}
      </div>
      <form className="main-container" onSubmit={(e) => placeOrder(e)}>
        <div className="form-container">
          <div className="form-data">
            <p className="form-title">
              This is a <b className="text-uppercase">Delivery Order</b>
              <br />
              <span>Just a last step, please enter your details:</span>
            </p>
          </div>
          <div className="form-data">
            <div className="form-title-div">
              <label className="lable">Title*</label>
              <div className="dropdown-and-input">
                <div className="input-main-container" onClick={toggleOptions}>
                  <input
                    ref={inputRef}
                    placeholder="Mr"
                    className="title-dropdown"
                    readOnly
                  />
                  <div className="tilte-line"></div>
                  <img
                    src={Arrow}
                    width={20}
                    height={20}
                    className="arrow-image"
                  />
                </div>
                {showOptions && (
                  <div
                    className={`title-dropdown-list ${showOptions ? "title-dropdown-open" : ""
                      }`}
                  >
                    {options.map((option) => (
                      <div
                        key={option.value}
                        className="title-dropdown-option"
                        onClick={() => handleOptionSelect(option)}
                      >
                        {option.label}
                      </div>
                    ))}
                  </div>
                )}
              </div>
            </div>
            <div className="full-name-main-container">
              <label className="lable">Full Name*</label>
              <div className="name-input-div">
                <input
                  type="text"
                  placeholder="Enter your full name "
                  // className="simple-input"
                  className="simple-input"
                  onChange={(e) => setName(e.target.value)}
                  value={name}
                // required
                />
              </div>
            </div>
          </div>
          <div className="form-data">
            <div className="second-input-div">
              <label className="lable">Mobile Number*</label>
              <div className="name-input-div">
                <input
                  placeholder="03xx-xxxxxxx"
                  className="simple-input"
                  onChange={handleMobileNumberChange}
                  value={mobileNumber}
                // required
                />
              </div>
            </div>
            <div className="second-input-div">
              <label className="lable">Alternate Number</label>
              <div className="name-input-div">
                <input
                  placeholder="03xx-xxxxxxx"
                  className="simple-input"
                  onChange={handleAlternateNumberChange}
                  value={alternateNumber}
                // required
                />
              </div>
            </div>
          </div>
          <div className="form-data">
            <div className="area-input-div">
              <label className="lable">Area</label>
              <div className="name-input-div">
                <input
                  disabled
                  type="text"
                  // placeholder={orderLocation.value}
                  className="location-input"
                  value={orderLocation.value}
                  readOnly
                />
              </div>
            </div>
            <div className="deliver-address-input-div">
              <label className="lable">Delivery Address*</label>
              <div className="name-input-div">
                <textarea
                  style={{ resize: "none" }}
                  type="text"
                  placeholder="Enter your complete delievery address"
                  className="simple-input"
                  onChange={(e) => setAddress(e.target.value)}
                  value={address}
                  // required
                  maxLength="180"
                ></textarea>
              </div>
            </div>
          </div>
          <div className="form-data">
            <div className="second-input-div">
              <label className="lable">Nearest Landmark</label>
              <div className="name-input-div">
                <input
                  placeholder="any famous place nearby"
                  className="simple-input"
                  onChange={(e) => setLandmark(e.target.value)}
                  value={landmark}
                // required
                />
              </div>
            </div>
            <div className="second-input-div">
              <label className="lable">Email Address*</label>
              <div className="name-input-div">
                <input
                  placeholder="Please enter the email"
                  className="simple-input"
                  onChange={(e) => setEmail(e.target.value)}
                  value={email}
                // onBlur={validateEmail}
                // required
                />
              </div>
            </div>
          </div>
          <div className="form-data">
            <div style={{ width: "100%" }}>
              <label className="lable">Delivery Insturction</label>
              <div className="name-input-div">
                <textarea
                  style={{ resize: "vertical" }}
                  type="text"
                  placeholder="Enter any insturction or note to rider"
                  className="instructions"
                  onChange={(e) => setInstructions(e.target.value)}
                  value={instructions}
                // required
                />
              </div>
            </div>
          </div>
          <div className="Payment-information-container">
            <p>Payment Information</p>
            <div className="payment-method">
              {enableMethod &&
                enableMethod?.map((item) => (
                  <div
                    style={{
                      cursor: item.enable === false ? "not-allowed" : "pointer",
                    }}
                    key={item?._id}
                    tabIndex="-1"
                    className={
                      paymentMethod === item.method
                        ? "payment-style"
                        : "cash-div"
                    }
                    onClick={() => {
                      if (item.enable === false) {
                        return;
                      } else {
                        handlePaymentMethod(item.method);
                      }
                    }}
                  >
                    <img
                      src={item?.method === "cash" ? Money : Card}
                      width={64}
                      height={37}
                    />
                    <span>{item.method}</span>
                  </div>
                ))}
            </div>
          </div>
          {paymentMethod == "cash" ? (
            <div className="change-request">
              <p>Change Request</p>

              <div className="change-input-field">
                <label>
                  <span className="change-input-text">Rs.</span>
                  <input
                    className="change-input"
                    placeholder="500"
                    onChange={(e) => setPayment(e.target.value)}
                    value={payment}
                    type="number"
                    min={0}
                  />
                </label>
              </div>
            </div>
          ) : (
            <></>
          )}
        </div>
        <div className="place-order-container">
          {products?.map((item, i) => {
            return (
              <div key={i} className="place-order-wrapper">
                <div key={item?._id} className="place-order-product">
                  <img
                    src={
                      `${LocalImageUrl}/${item?.images
                        ? item?.images[0]
                        : item?.variation?.images[0]}`
                    }
                    className="place-order-itme-image"
                    width="50px"
                    height="50px"
                    onError={(e) => {
                      e.target.src = NOImage; // Provide the URL of your dummy image
                    }}
                  />

                  <div className="order-placed-text-description">
                    <p className="place-order-text">{item?.name}</p>
                    <p className="place-order-description">
                      {" "}
                      {item.variation ? item?.variation?.name : null}
                    </p>
                    <p className="place-order-quantity">
                      {" "}
                      Price:{" "}
                      {item.variation ? item?.variation?.price : item.price}
                    </p>
                    <p className="place-order-quantity">
                      {" "}
                      Qty: {item.quantity}
                    </p>
                  </div>
                </div>
                <div className="place-order-price-container">
                  <p className="place-order-price">
                    {" "}
                    Rs.{" "}
                    {item.variation
                      ? item.variation.price * item.quantity
                      : item.price * item.quantity}
                  </p>
                </div>
              </div>
            );
          })}
          <div>
            <div className="total-main-check">
              <span className="total-price-text">Total</span>
              <span className="total-price-number">
                Rs. {location.state.totalPrice}
              </span>
            </div>
            <div className="total-main-check">
              <span className="total-price-text">
                Tax {location.state.tax}%
              </span>
              <span className="total-price-number">
                Rs. {Math.round(location.state.Totaltax)}
              </span>
            </div>
            {/* {orderLocation && orderLocation.deliveryCharges && ( */}

            {/* )} */}
            <div className="total-main-check">
              <span className="grandtotal-price-text">Sub Total</span>
              <span className="grandtotal-price-numnber">
                Rs.{" "}
                {Math.round(location.state.grandTotal)}
              </span>
            </div>
            <div className="total-main-check">
              <span className="total-price-text" style={{ width: "100%", textAlign: "center",color:"#646A6C",marginTop:"13px" }}>Delivery Charges will be Communicated on Call </span>
              {/* <span className="total-price-number">
                
              </span> */}
            </div>
          </div>
          <button type="submit" className="place-order-button ">
            {loading ? (
              <CircularProgress color="inherit" size={25} />
            ) : (
              "Place Order"
            )}
          </button>
          <div className="back-to-home">
            Delivered by {orderLocation.branch} Branch
          </div>
        </div>
      </form>
      <Thankyou
        thankyouModal={thankyouModal}
        setThankyouModal={setThankyouModal}
      />
      <Footer />
    </div>
  );
}
export default CheckoutDetail;
