import React, { Suspense } from "react";
import Loader from "../loader";

const MainComponent = React.lazy(() => import("../parentComponent"));

function HomePage() {
  return (
    <Suspense fallback={<Loader />}>
      <MainComponent />
    </Suspense>
  );
}
export default HomePage;
