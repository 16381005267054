import React, { Suspense } from "react";
import Loader from "../loader";
const MainComponent = React.lazy(() => import("../parentComponent"));
// import MainComponent from "../parentComponent";
function Clifton() {
  return (
    <Suspense fallback={<Loader />}>
      <MainComponent />
    </Suspense>
  );
}
export default Clifton;
