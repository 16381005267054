import { Route, BrowserRouter as Router, Routes } from "react-router-dom";
import NotFound from "../components/ui/404";
import CheckoutDetail from "../components/ui/checkout";
import Clifton from "../components/ui/clifton";
import HomePage from "../components/ui/home-page";
function AppRouter() {
  return (
    <Router>
      <Routes>
        <Route path="/" exact element={<HomePage />} />
        <Route path="/Checkout" element={<CheckoutDetail />} />
        {/* <Route path="/clifton" exact element={<Clifton />} /> */}
        <Route path="*" element={<NotFound />} />
      </Routes>
    </Router>
  );
}
export default AppRouter;
