import { useDispatch } from "react-redux";
import { Click } from "../../../config/icon";
import { clearAll } from "../../../config/productSlice";
import "./style.css";
function Thankyou({ thankyouModal, setThankyouModal }) {
  const dispatch = useDispatch();
  const orderSuccessful = () => {
    dispatch(clearAll());
    setThankyouModal(!thankyouModal);
  };
  return (
    thankyouModal && (
      <main className="thankyou-modal">
        <div className="thankyou-overlay" onClick={orderSuccessful}></div>
        <div className="thankyou-wrapper">
          <img src={Click} alt="" width={80} height={80} />
          <h3>Order Successful</h3>
          <p>Thank you so much for your order</p>
          <button onClick={orderSuccessful} className="back-home-page">
            Back Home Page
          </button>
        </div>
      </main>
    )
  );
}
export default Thankyou;
