// store.js
import { configureStore } from '@reduxjs/toolkit';
import productReducer from './productSlice'; // Apne actual reducer file ka naam istemal karein
import ModalReducer from './modalSlice'; // Apne actual reducer file ka naam istemal karein

const store = configureStore({
  reducer: {
    products: productReducer,
    modal:ModalReducer
  },
});

export default store;
