import { createSlice } from "@reduxjs/toolkit";
let productData = localStorage.getItem("product")
  ? JSON.parse(localStorage.getItem("product"))
  : [];
const productSlice = createSlice({
  name: "products",
  // initialState: { isModalOpen: false, productData: productData },
  initialState: productData,
  reducers: {
    // addProduct: (state, action) => {
    //   const item = action.payload;
    //   const inCart = state.find((i) => i._id === item._id);
    //   if (inCart) {
    //     console.log(state, "incart");
    //     state.map((i) => {
    //       if (i.variation._id === !item.variation._id) {
    //         state.push(item);
    //         let stateString = JSON.stringify(state);
    //         localStorage.setItem("product", stateString);
    //       } else if (!i.variation && i._id === item._id) {
    //         i.quantity += item.quantity;
    //       } else if (i.variation._id === item.variation._id) {
    //         i.quantity += item.quantity;
    //       }
    //       return i;
    //     });
    //     let stateString = JSON.stringify(state);
    //     localStorage.setItem("product", stateString);
    //   } else {
    //     state.push(item);
    //     let stateString = JSON.stringify(state);
    //     localStorage.setItem("product", stateString);
    //   }
    // },
    addProduct: (state, action) => {
      const item = action.payload;
      const inCart = state.find(
        (i) => i._id === item._id && i.variation?._id === item.variation?._id
      );
      if (inCart) {
        state = state.map((i) => {
          if (i._id === item._id && i.variation?._id === item.variation?._id) {
            i.quantity += item.quantity;
          }
          return i;
        });
      } else {
        state.push(item);
      }

      // Update local storage
      let stateString = JSON.stringify(state);
      localStorage.setItem("product", stateString);
    },

    removeProduct: (state, action) => {
      const removedProduct = action.payload;
      const inCartIndex = state.findIndex((i) => i._id === removedProduct._id);

      if (inCartIndex !== -1) {
        const updatedState = state
          .map((product, index) => {
            if (index === inCartIndex) {
              if (product.quantity > 1) {
                return { ...product, quantity: product.quantity - 1 };
              }
              return null;
            }
            return product;
          })
          .filter((product) => product !== null);
        let stateString = JSON.stringify(updatedState);
        localStorage.setItem("product", stateString);
        return updatedState;
      }
      let stateString = JSON.stringify(state);
      localStorage.setItem("product", stateString);
      return state;
    },
    clearAll: (state) => {
      let stateString = JSON.stringify([]);
      localStorage.setItem("product", stateString);
      return [];
    },
    checkoutRemove: (state, action) => {
      const removedProduct = action.payload;

      // Filter out products with the specified ID and checkboxItem
      const updatedState = state.filter(
        (product) =>
          !(
            product.id === removedProduct.id &&
            product.checkboxItem === removedProduct.checkboxItem
          )
      );
      let stateString = JSON.stringify(updatedState);
      localStorage.setItem("product", stateString);
      return updatedState;
    },
  },
});

export const { addProduct, removeProduct, clearAll, checkoutRemove } =
  productSlice.actions;

export default productSlice.reducer;
