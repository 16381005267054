// import React from "react";
// import { Loading } from "../../../config/icon";
// import "./style.css";
// import useTypewriter from "../useTypewriter";

// const Loader = () => {
//   // const displayText = useTypewriter("Loading ...", 100);
//   return (
//     <div className="loader">
//       <img src={Loading} alt="" />
//       {/* <p>{displayText}</p> */}
//     </div>
//   );
// };

// export default Loader;
import React, { useState, useEffect } from "react";
import { ZameerAnsariLogo } from "../../../config/icon";
import "./style.css";
const words = ["... ", "... ", "... ", "... ", "..."];
const staticText = "Website Loading ";
const textColor = "red"; // Color for the typed words

function Loader() {
  const [index, setIndex] = useState(0);
  const [text, setText] = useState(
    staticText + words[index].substring(0, 0) + "|"
  );

  useEffect(() => {
    function typeWord() {
      const word = words[index];
      let i = 0;

      function type() {
        if (i < word.length) {
          setText(
            staticText +
              `<span style="color:${textColor}">${word.substring(0, i)}</span>|`
          );
          i++;
          setTimeout(type, 100);
        } else {
          setTimeout(eraseWord, 2000);
        }
      }

      type();
    }

    function eraseWord() {
      let i = words[index].length;

      function erase() {
        if (i >= 0) {
          setText(
            staticText +
              `<span style="color:${textColor}">${words[index].substring(
                0,
                i
              )}</span>|`
          );
          i--;
          setTimeout(erase, 100);
        } else {
          setIndex((index + 1) % words.length);
          setTimeout(typeWord, 1000);
        }
      }

      erase();
    }

    typeWord();
  }, [index]);

  return (
    <div
      className="loader-wrapper"
      // style={{
      //   display: "flex",
      //   flexDirection: "column",
      //   alignItems: "center",
      //   justifyContent: "center",
      //   width: "100%",
      //   height: "100vh",
      // }}
    >
      <img src={ZameerAnsariLogo} alt="" className="loader-logo" />
      <p
        className="loaderTyping"
        id="typing"
        dangerouslySetInnerHTML={{ __html: text }}
      />
    </div>
  );
}

export default Loader;
