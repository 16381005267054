import React from "react";
import Product1 from "../../../assets/product-1.jpg";
import Product2 from "../../../assets/product-2.png";
import {
  Email,
  Facebook,
  FooterLocation,
  Instagram,
  PhoneReceiver,
  TopScroll,
  ZameerAnsariLogo,
} from "../../../config/icon";
import "./style.css";
function ContactInfo({ icon, title, value }) {
  return (
    <div className="email">
      <div className="line"></div>
      <img
        src={icon}
        alt=""
        width={16}
        height={16}
        style={{ marginTop: "4px" }}
      />
      <div className="footer-phone-div">
        <p className="footer-phone-text">{title}</p>
        <p className="footer-phone-number">{value}</p>
      </div>
    </div>
  );
}
function Footer() {
  const location = JSON.parse(localStorage.getItem("location"));
  const scrollToTop = () => {
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  };
  const { branch } = location || {};
  const phone = "+021-111-77-64-68";
  const email = "Info@zameeransari.com.pk";
  const instagramLink = "https://www.instagram.com/";
  return (
    <footer className="footer">
      <main className="main-footer">
        <div>
          <div className="phone-footer">
            <div className="line"></div>
            <img
              src={PhoneReceiver}
              alt=""
              width={16}
              height={16}
              style={{ marginTop: "4px" }}
            />
            <div className="footer-phone-div">
              <p className="footer-phone-text">Phone</p>
              <p className="footer-phone-number">{branch==="Clifton"?"0331-7926337":"0334-4926337"}</p>
            </div>
          </div>
          <ContactInfo icon={Email} title="Email" value={email} />
          <ContactInfo icon={FooterLocation} title="Address" value={branch} />
        </div>
        <hr className="responsive-line" />
        <div className="read-more-container">
          <div className="logo-container">
            {/* <img src={Logo} alt="" width={150} height={80} /> */}
            <img src={ZameerAnsariLogo} alt="" width={100} height={80} />
          </div>
          <p className="read-more-para">
            The Best BBQ Restaurant in Karachi Since 1984. Serving The Best
            Quality Food with Delivery all Over Karachi
          </p>
        </div>
        <hr className="responsive-line" />

        <div className="instgram-feeds">
          <p className="use-ful-link" style={{ marginBottom: 10 }}>
            Instagram Feeds
          </p>
          <img
            src={Product1}
            width={50}
            alt=""
            height={50}
            style={{ marginRight: 10 }}
          />
          <img
            src={Product2}
            alt=""
            width={50}
            height={50}
            style={{ marginRight: 10 }}
          />
          <img
            src={Product1}
            alt=""
            width={50}
            height={50}
            style={{ marginRight: 10 }}
          />
          <img
            src={Product2}
            alt=""
            width={50}
            height={50}
            style={{ marginRight: 10 }}
          />
          <p>
            <a
              className="instagram-link"
              href={`${
                branch === "Clifton"
                  ? "https://www.instagram.com/zameeransaricliftonchapter/"
                  : "https://www.instagram.com/zameeransariofficial/"
              }`}
              target="_blank"
            >
              See more
            </a>
          </p>
        </div>
      </main>
      <hr className="footer-line" />
      <div className="scroll-and-copy-right">
        <div className="copy-right-div">
          <p className="copy-right-text">
            © 2023 <span className="best-tech"> ZameerAnsari</span>, All rights
            reserved.
          </p>
          <div className="social-links">
            <a
              href={`${
                branch === "Clifton"
                  ? "https://www.facebook.com/zameeransariclifton"
                  : "https://www.facebook.com/zameeransariofficial"
              }`}
              target="_blank"
            >
              <img
                src={Facebook}
                alt=""
                className="cursor"
                width={32}
                height={32}
              />
            </a>
            <a
              href={`${
                branch === "Clifton"
                  ? "https://www.instagram.com/zameeransaricliftonchapter/"
                  : "https://www.instagram.com/zameeransariofficial/"
              }`}
              target="_blank"
            >
              <img
                src={Instagram}
                alt=""
                className="cursor"
                width={32}
                height={32}
              />
            </a>
          </div>
        </div>
        <img src={TopScroll} alt="" className="cursor" onClick={scrollToTop} />
      </div>
    </footer>
  );
}
export default React.memo(Footer);
